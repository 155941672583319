window.esignContact = window.esignContact || {};

esignContact.init = function () {
  if(!Modernizr.touch) {
    $('.validate').validationEngine({scrollOffset: 115});
  } else {
    $('.validate').validationEngine({scroll: false});
  }

  esignContact.formAjax();

  $('select[name="subject"]').change(function(e) {
    var option = $(this)
      .find('option:selected')
      .data('option');

    $('.js-subject-option').hide();
    if (option) {
      $('.js-subject-option.option-' + option).show();
    }
  });
};

/* Captcha */
esignContact.formAjax = function () {
  $('.form-ajax-recaptcha').submit(function(e) {
    e.preventDefault();
    var $form = $(this);


    if($form.hasClass('validate')){
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != ''){
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

/**
 * async ReCaptcha inladen en toevegen aan elk recaptcha blokje
 */
var onloadReCaptchaCallback = function() {
  $('.g-recaptcha').each(function(i,el){
    var attributes = {
      'sitekey'  : $(el).data('sitekey'),
      'size'     : $(el).data('size'),
      'callback' : $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid',widgetid);
  });
};

function onSubmitContact(token) {
  var $form = $('#contactForm');

  esignContact.recaptchaFormSend($form);
};

function onSubmitAppointment(token) {
  var $form = $('#appointmentForm');

  esignContact.recaptchaFormSend($form);
};

function onSubmitDocument(token) {
  var $form = $('#documentForm');

  esignContact.recaptchaFormSend($form);
};


esignContact.recaptchaFormSend = function(form){

  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      if ($form.data('title') !== '' && $form.data('label') !== '') {
        window.dataLayer = window.dataLayer || [];
        var $label = $form.data('label');

        if ($form.find('select#subject').val()){
          var $subject = $form.find('select#subject').val();
          $label = $label + ' - ' + $subject;
        }

        window.dataLayer.push({
          'event': 'formSubmission',
          'formTitle': $form.data('title'),
          'formLabel': $label,
        });
      }

      $form.html('<div class="note note--success">' + data.result + '</div>');

      // reset documents
      if ($form.attr('action').indexOf('/document') > 0) {

        $('.js-download-form .js-download-form__link').each(function(i, el) {
          var $el = $(el);
          $el.attr('href', $el.data('href'));
          console.log($el);

          var $link = $('<a href="' + $el.attr('href') + '" target="_blank" rel="noopener" class="dummy-button">' + $el.html() + '</a>');
          $link.insertAfter($el);

          $el.remove();
        });
      }

    } else {
      $result.html('<div class="note note--error">' + data.result + '</div>');
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function(response) {
    alert('Error: ' + response.responseText);
  });

};

// initialize on docready
$(esignContact.init);
